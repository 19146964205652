import type { TrialAssociation } from '../types/TrialAssociation'
import type { TrialForSummaryCard } from './transformTrialForTrialSummaryCard'

/**
 * This function gets the preferred title for a trial. Researchers can modify the "abbreviatedTitle" that should override any inferred trial name (conjured name from gpt, or template string name)
 */
export default function getPreferredTrialTitle(
  trial: Pick<
    TrialAssociation,
    'abbreviatedTitle' | 'briefTitle' | 'conjuredTitle'
  >,
) {
  return trial.abbreviatedTitle ?? trial.conjuredTitle ?? trial.briefTitle
}

export const getPreferredTrialTitleForAlgoliaTrial = (
  trial: TrialForSummaryCard,
  conditionToDisplay: string,
) => {
  const primaryTreatments = trial.treatments?.filter((t) => t.isPrimary) ?? []

  const [primaryTreatment] = primaryTreatments
  const plusMore =
    primaryTreatments.length > 1 ? ` +${primaryTreatments.length - 1} More` : ''

  const treatmentString = primaryTreatment
    ? `${primaryTreatment.name}${plusMore}`
    : trial.primaryTreatment

  const templateFallback = `${treatmentString} for ${conditionToDisplay}`

  return trial.abbreviatedTitle ?? trial.conjuredTitle ?? templateFallback
}
