'use client'

import useTrialApplicationLink from '@components/hooks/useTrialApplicationLink'
import type { ButtonProps } from '@components/primitives/Button'
import Button from '@components/primitives/Button'
import { Icon } from '@components/primitives/Icon'
import { EllipsesPulse } from '@components/primitives/LoadingWrapper/EllipsesPulse'
import { classMerge } from '@components/utilities/classMerge'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird'
import { trackEventWithFacebook } from '@lib/tracking'
import { TrackingEvents } from '@lib/tracking/TrackingEvents'
import type { TrackingSources } from '@lib/tracking/TrackingSources'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import { useEffect, useState } from 'react'

export type ApplyToTrialButtonProps = Omit<ButtonProps, 'children'> & {
  activeSpinner?: boolean
  buttonText?: string
  newTab?: boolean
  prefetch?: boolean
  source?: TrackingSources
  swallowClick?: boolean
  trial: Pick<TrialAssociation, 'nctId' | 'overallStatus' | 'slug'>
}

export const ApplyToTrialButton = ({
  activeSpinner = false,
  buttonText = 'Apply Now',
  newTab = false,
  prefetch = true,
  source,
  swallowClick = false,
  trial,
  ...buttonProps
}: ApplyToTrialButtonProps) => {
  const trialApplicationLink = useTrialApplicationLink(trial)
  const [isSpinning, setIsSpinning] = useState(activeSpinner)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1500)
  })

  const icon = isSpinning ? faSpinnerThird : faArrowRight
  const animateClassName = isSpinning
    ? 'motion-safe:animate-spin'
    : 'motion-safe:animate-none'

  const buttonColor = buttonProps.color ?? 'black'
  const ellipsesColor = buttonColor === 'white' ? 'bg-black' : 'bg-white'

  return (
    <Button.Link
      {...buttonProps}
      className={classMerge('w-full rounded-full', buttonProps.className)}
      color={buttonColor}
      href={trialApplicationLink}
      linkPrefetch={prefetch}
      newTab={newTab}
      onClick={(e: React.MouseEvent) => {
        if (swallowClick) {
          e.stopPropagation()
        }
        setIsSpinning(true)
        trackEventWithFacebook(TrackingEvents.APPLY_NOW_CLICKED, {
          source,
        })
      }}
      rel='nofollow'
      value={
        isLoading ? (
          <div className='h-10'>
            <EllipsesPulse
              className='justify-center'
              ellipsesColor={ellipsesColor}
            />
          </div>
        ) : (
          <span>
            {buttonText}
            <Icon
              className={classMerge(animateClassName, 'ml-2')}
              icon={icon}
            />
          </span>
        )
      }
    />
  )
}
